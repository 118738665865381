body {
  margin: 0 auto;
  min-width: 320px;
  max-width: 500px;
  background: #fff;
}

p,
div,
img {
  padding: 0;
  margin: 0;
}

.author-container {
  position: fixed;
  top: 0;
  width: 100%;
  height: 48px;
  border-bottom: 1px solid #DBDCDD;
  z-index: 100;
  background: #fff;
}

.avatar-container {
  position: absolute;
  top: 10px;
  left: 12px;
  height: 30px;
  width: 30px;
}

.avatar-img {
  width: 30px;
  height: 30px;
  border-radius: 30px;
}

.author-info-container {
  position: absolute;
  top: 11px;
  left: 52px;
  font-size: 12px;
}

.author-name {
  height: 14px;
  line-height: 14px;
  color: #4A4A4A;
}

.author-title {
  height: 14px;
  line-height: 14px;
  margin-top: 2px;
  color: #9B9B9B;
}

.author-container-initial {
  position: relative;
}

.landing-img {
  width: 100%;
}

.landing-avatar-container {
  text-align: center;
  position: absolute;
  bottom: -16px;
  width: 100%;
}

.landing-author-info {
  position: absolute;
  text-align: center;
  width: 100%;
  bottom: -44px;
}

.landing-author-name {
  font-size: 12px;
  line-height: 14px;
  color: #4A4A4A;
}

.landing-author-title {
  font-size: 12px;
  line-height: 14px;
  color: #9B9B9B;
}

.article-content-container {
  padding-top: 6px;
  margin-top: 50px;
}

.bold-container,
.text-container {
  margin-left: 5%;
  width: 90%;
  position: relative;
  padding-bottom: 12.5px;
}

.bold-container {
  padding-top: 8px;
}

.bold-title-icon {
  width: 3px;
  height: 12px;
  background: #50E3C2;
  position: absolute;
  top: 9px;
  left: 0;
}

.bold-title-content {
  font-size: 14px;
  min-height: 14px;
  line-height: 14px;
  margin-left: 10px;
  font-weight: bold;
  word-wrap: break-word;
  word-break: break-all;
}

.text-content {
  font-size: 14px;
  color: #4A4A4A;
  line-height: 22px;
  letter-spacing: 0.6px;
  word-wrap: break-word;
  word-break: break-all;
  overflow: hidden;
}

.img-container {
  width: 100%;
  position: relative;
  padding-bottom: 12.5px;
}

.article-content-img {
  width: 100%;
}

.copyright-container {
  height: 58px;
  width: 100%;
  color: #BDBDBD;
  background-color: #F5F6F7;
  margin-bottom: 56px;
}

.copyright-title {
  text-align: center;
  font-size: 10px;
  padding-top: 10px;
}

.copyright-content {
  text-align: left;
  width: 90%;
  padding-left: 5%;
  padding-top: 4.5px;
  font-size: 9px;
}

.download-area {
  position: fixed;
  bottom: 0;
  width: 100%;
  min-width: 320px;
  max-width: 500px;
  height: 56px;
  background: #50E3C2;
}

.download-area p {
  color: #fff;
  font-size: 13px;
  line-height: 20px;
  height: 20px;
  margin-left: 17px;
}

.download-ad {
  margin-top: 8px;
}

.download-link {
  color: #50E3C2;
  background: #fff;
  position: fixed;
  width: 93px;
  height: 30px;
  line-height: 30px;
  bottom: 13px;
  right: 17px;
  font-size: 12px;
  text-align: center;
  text-decoration: none;
  cursor: none;
  border-radius: 76px;
}

@media screen and (min-width: 360px) and (max-width: 399px) {
  .author-container {
    height: 58px;
  }
  .avatar-container {
    top: 12px;
    left: 14px;
    height: 36px;
    width: 36px;
  }
  .avatar-img {
    width: 36px;
    height: 36px;
    border-radius: 36px;
  }
  .author-info-container {
    top: 13px;
    left: 62px;
    font-size: 14px;
  }
  .author-name {
    height: 17px;
    line-height: 17px;
  }
  .author-title {
    height: 17px;
    line-height: 17px;
    margin-top: 2px;
  }
  .landing-avatar-container {
    bottom: -20px;
  }
  .landing-author-info {
    bottom: -53px;
  }
  .landing-author-name {
    font-size: 14px;
    line-height: 17px;
  }
  .landing-author-title {
    font-size: 14px;
    line-height: 17px;
  }
  .article-content-container {
    padding-top: 8px;
    margin-top: 60px;
  }
  .bold-container,
  .text-container {
    padding-bottom: 15px;
  }
  .bold-container {
    padding-top: 10px;
  }
  .bold-title-icon {
    width: 4px;
    height: 14px;
    top: 10px;
  }
  .bold-title-content {
    font-size: 16px;
    min-height: 16px;
    line-height: 16px;
    margin-left: 12px;
  }
  .text-content {
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.7px;
    word-wrap: break-word;
    word-break: break-all;
  }
  .img-container {
    padding-bottom: 15px;
  }
  .copyright-container {
    height: 70px;
    margin-bottom: 67px;
  }
  .copyright-title {
    font-size: 12px;
    padding-top: 12px;
  }
  .copyright-content {
    padding-top: 5.5px;
    font-size: 11px;
  }
  .download-area {
    height: 67px;
  }
  .download-area p {
    font-size: 15px;
    line-height: 24px;
    height: 24px;
    margin-left: 20px;
  }
  .download-ad {
    margin-top: 9px;
  }
  .download-link {
    width: 112px;
    height: 36px;
    line-height: 36px;
    bottom: 15px;
    right: 20px;
    font-size: 14px;
    border-radius: 91px;
  }
}

@media screen and (min-width: 400px) {
  .author-container {
    height: 64px;
  }
  .avatar-container {
    top: 13px;
    left: 15px;
    height: 40px;
    width: 40px;
  }
  .avatar-img {
    width: 40px;
    height: 40px;
    border-radius: 40px;
  }
  .author-info-container {
    top: 14px;
    left: 68px;
    font-size: 16px;
  }
  .author-name {
    height: 19px;
    line-height: 19px;
  }
  .author-title {
    height: 19px;
    line-height: 19px;
    margin-top: 2px;
  }
  .landing-avatar-container {
    bottom: -22px;
  }
  .landing-author-info {
    bottom: -58px;
  }
  .landing-author-name {
    font-size: 16px;
    line-height: 19px;
  }
  .landing-author-title {
    font-size: 16px;
    line-height: 19px;
  }
  .article-content-container {
    padding-top: 9px;
    margin-top: 66px;
  }
  .bold-container,
  .text-container {
    padding-bottom: 16.5px;
  }
  .bold-container {
    padding-top: 11px;
  }
  .bold-title-icon {
    width: 4px;
    height: 16px;
    top: 12px;
  }
  .bold-title-content {
    font-size: 18px;
    min-height: 18px;
    line-height: 18px;
    margin-left: 13px;
  }
  .text-content {
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 1px;
  }
  .img-container {
    padding-bottom: 16.5px;
  }
  .copyright-container {
    height: 77px;
    margin-bottom: 74px;
  }
  .copyright-title {
    font-size: 14px;
    padding-top: 13px;
  }
  .copyright-content {
    padding-top: 6px;
    font-size: 12px;
  }
  .download-area {
    height: 74px;
  }
  .download-area p {
    font-size: 16px;
    line-height: 26px;
    height: 26px;
    margin-left: 22px;
  }
  .download-ad {
    margin-top: 10px;
  }
  .download-link {
    width: 122px;
    height: 40px;
    line-height: 40px;
    bottom: 16px;
    right: 22px;
    font-size: 16px;
    border-radius: 100px;
  }
}
